import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
import Filter from "../../components/Utilities/Filter"
import uniqueVals from "../../js/uniqueVals"

class FavouriteOpponents extends React.Component {

  state = {
    statView: 'Goals',
    orderByRatio: false,
    comp: '',
    minGames: ''
  };

  manageStatView = (view) => {
    this.setState({ statView: view });
  };

  orderByRatio = () => {
    this.setState(state => ({ orderByRatio: !state.orderByRatio }));
  };
  
  comp = (e) => { this.setState({comp: e.target.value }); };
  minGames = (e) => { this.setState({minGames: e.target.value }); };

  render() {

    // Get the data for each player - filter out rows that aren't matches
    let mdata = this.props.data.allSheetMessiMatchHistory.edges.filter(d => d.node.team)
    let rdata = this.props.data.allSheetRonaldoMatchHistory.edges.filter(d => d.node.team)

    const competitions = ["All Club Competitions","All International Competitions","All League","La Liga","Premier League","Serie A","Ligue 1","Champions League","Champions League Qualifying","Europa League","Domestic Cups","Domestic Super Cups","UEFA Super Cup","Club World Cup","Euros/Copa","World Cup","World Cup Qualifier","Euros Qualifier","UEFA Nations League","Confederations Cup","Finalissima","All Competitive Internationals"]

    // Filter data by competition based on filter choice
    mdata = this.state.comp === "All League" ?
      mdata.filter(d => d.node.competition === "La Liga" || d.node.competition === "Serie A" || d.node.competition === "Premier League" || d.node.competition === "Ligue 1" || d.node.competition === "MLS" || d.node.competition === "Saudi Pro League")
    : this.state.comp === "Domestic Cups" ?
      mdata.filter(d => d.node.competition === "Copa del Rey" || d.node.competition === "FA Cup" || d.node.competition === "Coppa Italia" || d.node.competition === "Coupe de France" || d.node.competition === "US Open Cup" || d.node.competition === "MLS Cup" || d.node.competition === "Taça de Portugal")
    : this.state.comp === "Domestic Super Cups" ?
      mdata.filter(d => d.node.competition === "Supercopa de Espana" || d.node.competition === "Campeones Cup" || d.node.competition === "Supercoppa Italiana" || d.node.competition === "Saudi Super Cup" || d.node.competition === "Trophée des Champions")
    : this.state.comp === "Euros/Copa" ?
      mdata.filter(d => d.node.competition === "Copa America" || d.node.competition === "UEFA European Championships")
    : this.state.comp === "" || this.state.comp === "All Club + Country" ?
      mdata
    : this.state.comp === "All Club Competitions" ?
      mdata.filter(d => d.node.team !== "Argentina")
    : this.state.comp === "All International Competitions" ?
      mdata.filter(d => d.node.team === "Argentina")
    : this.state.comp === "All Competitive Internationals" ?
      mdata.filter(d => d.node.competition === "Copa America" || d.node.competition === "World Cup" || d.node.competition === "World Cup Qualifier" || d.node.competition === "Finalissima")
    : mdata.filter(d => d.node.competition === this.state.comp)

    rdata = this.state.comp === "All League" ?
      rdata.filter(d => d.node.competition === "La Liga" || d.node.competition === "Serie A" || d.node.competition === "Premier League" || d.node.competition === "Ligue 1" || d.node.competition === "Primeira Liga" || d.node.competition === "Saudi Pro League")
    : this.state.comp === "Domestic Cups" ?
      rdata.filter(d => d.node.competition === "Copa del Rey" || d.node.competition === "FA Cup" || d.node.competition === "Coppa Italia" || d.node.competition === "Coupe de France" || d.node.competition === "Saudi King Cup" || d.node.competition === "League Cup" || d.node.competition === "Taça de Portugal")
    : this.state.comp === "Domestic Super Cups" ?
      rdata.filter(d => d.node.competition === "Supercopa de Espana" || d.node.competition === "FA Community Shield" || d.node.competition === "Supercoppa Italiana" || d.node.competition === "Saudi Super Cup" || d.node.competition === "Trophée des Champions")
    : this.state.comp === "Euros/Copa" ?
      rdata.filter(d => d.node.competition === "Copa America" || d.node.competition === "UEFA European Championships")
    : this.state.comp === "" || this.state.comp === "All Club + Country" ?
      rdata
    : this.state.comp === "All Club Competitions" ?
      rdata.filter(d => d.node.team !== "Portugal")
    : this.state.comp === "All International Competitions" ?
      rdata.filter(d => d.node.team === "Portugal")
    : this.state.comp === "All Competitive Internationals" ?
      rdata.filter(d => d.node.competition === "UEFA European Championships" || d.node.competition === "Euros Qualifier" || d.node.competition === "World Cup Qualifier" || d.node.competition === "UEFA Nations League" || d.node.competition === "Confederations Cup" || d.node.competition === "World Cup")
    : rdata.filter(d => d.node.competition === this.state.comp)

    // Create a new array containing each unique opponent
    let mopponents = uniqueVals(mdata.map(d => d.node.opponent)).sort()
    let ropponents = uniqueVals(rdata.map(d => d.node.opponent)).sort()

    // For each unique opponent, get the following totals and put them in a new array
    mopponents = mopponents.map(opponent => [{
      'opponent': opponent,
      'goals': mdata.filter(d => d.node.opponent === opponent).reduce((total, d) => { return (total + Number(d.node.goals)) }, 0),
      'assists': mdata.filter(d => d.node.opponent === opponent).reduce((total, d) => { return (total + Number(d.node.assists)) }, 0),
      'apps': mdata.filter(d => d.node.opponent === opponent).reduce((total, d) => { return (total + 1) }, 0),
      'ga': (mdata.filter(d => d.node.opponent === opponent).reduce((total, d) => { return (total + Number(d.node.goals)) }, 0))+(mdata.filter(d => d.node.opponent === opponent).reduce((total, d) => { return (total + Number(d.node.assists)) }, 0))
    }])

    ropponents = ropponents.map(opponent => [{
      'opponent': opponent,
      'goals': rdata.filter(d => d.node.opponent === opponent).reduce((total, d) => { return (total + Number(d.node.goals)) }, 0),
      'assists': rdata.filter(d => d.node.opponent === opponent).reduce((total, d) => { return (total + Number(d.node.assists)) }, 0),
      'apps': rdata.filter(d => d.node.opponent === opponent).reduce((total, d) => { return (total + 1) }, 0),
      'ga': (rdata.filter(d => d.node.opponent === opponent).reduce((total, d) => { return (total + Number(d.node.goals)) }, 0))+(rdata.filter(d => d.node.opponent === opponent).reduce((total, d) => { return (total + Number(d.node.assists)) }, 0))
    }])

    // Order the opponents based on number of appearances
    mopponents = this.state.orderByRatio ?
      mopponents.sort((a, b) => parseFloat(b[0].apps) - parseFloat(a[0].apps))
    : mopponents.sort((a, b) => parseFloat(a[0].apps) - parseFloat(b[0].apps))

    ropponents = this.state.orderByRatio ?
      ropponents.sort((a, b) => parseFloat(b[0].apps) - parseFloat(a[0].apps))
    : ropponents.sort((a, b) => parseFloat(a[0].apps) - parseFloat(b[0].apps))
    
    // Order the opponents based on chosen stat (goals, assists, G+A etc.)
    mopponents = this.state.statView === "Goals" && this.state.orderByRatio === true ?
      mopponents.sort((a, b) => (Number(b[0].goals / b[0].apps).toFixed(2)) - (Number(a[0].goals / a[0].apps).toFixed(2)))
    : this.state.statView === "Assists" && this.state.orderByRatio === true ?
      mopponents.sort((a, b) => (Number(b[0].assists / b[0].apps).toFixed(2)) - (Number(a[0].assists / a[0].apps).toFixed(2)))
    : this.state.statView === "GA" && this.state.orderByRatio === true ?
      mopponents.sort((a, b) => (Number(b[0].ga / b[0].apps).toFixed(2)) - (Number(a[0].ga / a[0].apps).toFixed(2)))
    : this.state.statView === "Goals" ?
      mopponents.sort((a, b) => parseFloat(b[0].goals) - parseFloat(a[0].goals))
    : this.state.statView === "Assists" ?
    mopponents.sort((a, b) => parseFloat(b[0].assists) - parseFloat(a[0].assists))
    : this.state.statView === "GA" ?
    mopponents.sort((a, b) => parseFloat(b[0].ga) - parseFloat(a[0].ga))
    : ''

    ropponents = this.state.statView === "Goals" && this.state.orderByRatio === true ?
      ropponents.sort((a, b) => (Number(b[0].goals / b[0].apps).toFixed(2)) - (Number(a[0].goals / a[0].apps).toFixed(2)))
    : this.state.statView === "Assists" && this.state.orderByRatio === true ?
      ropponents.sort((a, b) => (Number(b[0].assists / b[0].apps).toFixed(2)) - (Number(a[0].assists / a[0].apps).toFixed(2)))
    : this.state.statView === "GA" && this.state.orderByRatio === true ?
      ropponents.sort((a, b) => (Number(b[0].ga / b[0].apps).toFixed(2)) - (Number(a[0].ga / a[0].apps).toFixed(2)))
    : this.state.statView === "Goals" ?
      ropponents.sort((a, b) => parseFloat(b[0].goals) - parseFloat(a[0].goals))
    : this.state.statView === "Assists" ?
    ropponents.sort((a, b) => parseFloat(b[0].assists) - parseFloat(a[0].assists))
    : this.state.statView === "GA" ?
    ropponents.sort((a, b) => parseFloat(b[0].ga) - parseFloat(a[0].ga))
    : ''

    // Remove unnecessary array dimension
    mopponents = mopponents.map(d => d[0])
    ropponents = ropponents.map(d => d[0])

    // Only show opponents that have played a minimum of x number of games
    mopponents = this.state.minGames ? mopponents.filter(d => d.apps >= this.state.minGames)
    : mopponents

    ropponents = this.state.minGames ? ropponents.filter(d => d.apps >= this.state.minGames)
    : ropponents

    return (

      <Layout>
        <SEO
          title={"Favourite Opponents for Club and Country - Messi vs Ronaldo"}
          description={`See which teams Messi and Ronaldo have scored against the most often in their careers for both club and country... and who they've struggled against!`}
          canonicalPath={`/all-time-stats/favourite-opponents/`}
        />

        <h1>
        Favourite Opponents <span className="sr-only">of Messi and Ronaldo</span>
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'Goals', 'url': 'none', 'stateHandler': () => this.manageStatView('Goals'), 'active': this.state.statView === "Goals" ? true : false },
            { 'name': 'Assists', 'url': 'none', 'stateHandler': () => this.manageStatView('Assists'), 'active': this.state.statView === "Assists" ? true : false },
            { 'name': 'Goals + Assists', 'url': 'none', 'stateHandler': () => this.manageStatView('GA'), 'active': this.state.statView === "GA" ? true : false },
          ]}
          btnTextSize="text-xs lg:text-sm"
          btnPadding="px-3 py-1 lg:px-4 lg:py-2"
        />

        <div className="flex flex-wrap mt-6 max-w-xs mx-auto px-3 py-0 filters">
          <Filter
            label="All Club + Country"
            array={competitions}
            onChangeFunc={this.comp}
            value={this.state.comp}
            width="w-2/3"
          />
          <Filter
            label="Minimum Games Played"
            firstOption="Min 1 game"
            array={[{"text":"Min 2 games","value":'2'},{"text":"Min 3 games","value":'3'},{"text":"Min 4 games","value":'4'},{"text":"Min 5 games","value":'5'},{"text":"Min 6 games","value":'6'},{"text":"Min 7 games","value":'7'},{"text":"Min 8 games","value":'8'},{"text":"Min 9 games","value":'9'},{"text":"Min 10 games","value":'10'}]}
            onChangeFunc={this.minGames}
            value={this.state.minGames}
            width="w-1/3"
          />
          <div className="w-full mt-1 text-center max-w-2xs mx-auto">
            <input type="checkbox" checked={this.state.orderByRatio ? true : false} onChange={this.orderByRatio} id="ratio" />
            <label className="ml-1 text-sm text-gray-500" htmlFor="ratio">Order by ratio</label>
          </div>
        </div>

        <div className="flex mt-8 w-full max-w-screen-lg mx-auto">

          <div className="w-1/2">
            <h2 className="text-messi-darken10 uppercase tracking-wider text-sm p-2 text-center"><span className="sr-only 2xs:not-sr-only">Lionel</span> Messi <span className="sr-only">Favourite Opponents</span></h2>
            <ol className="p-1 flex flex-col border-r border-gray-900 md:pr-3 lg:pr-6">
              {mopponents.map((d, index) => (
                <li key={d.opponent} className="border-t border-gray-700 md:flex">
                  <div className="flex items-center border-b border-gray-900 text-sm lg:text-base md:flex-1 md:truncate">
                    <span className="bg-noir-lighten5 p-2 inline-block text-highlight text-sm">{index+1}.</span>
                    <span className="p-1 pl-2 truncate">{d.opponent}</span>
                  </div>
                  <div className={`flex md:flex-1`}>
                    <div className="flex flex-col text-center border-r border-gray-900 flex-auto text-messi">
                      <span className="p-1 pb-0 font-semibold">
                        {this.state.statView === "Goals" ? d.goals : this.state.statView === "Assists" ? d.assists : this.state.statView === "GA" ? d.ga : ''}
                      </span>
                      <span className="text-xs block pb-2 leading-tight">
                        {this.state.statView === "Goals" ? 'Goals' : this.state.statView === "Assists" ? 'Assists' : this.state.statView === "GA" ? 'G+A' : ''}
                      </span>
                    </div>
                    <div className="flex flex-col text-center border-r border-gray-900 flex-auto">
                      <span className="p-1 pb-0 text-gray-500">{d.apps}</span>
                      <span className="text-xs text-gray-600 block pb-2 leading-tight">Apps</span>
                    </div>
                    <div className="flex flex-col text-center flex-auto">
                      <span className="p-1 pb-0 text-gray-500">
                        {this.state.statView === "Goals" ? Number(d.goals / d.apps).toFixed(2) : this.state.statView === "Assists" ? Number(d.assists / d.apps).toFixed(2) : this.state.statView === "GA" ? Number(d.ga / d.apps).toFixed(2) : ''}
                      </span>
                      <span className="text-xs text-gray-600 block pb-2 leading-tight">Per Game</span>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>

          <div className="w-1/2">
            <h2 className="text-ronaldo-darken10 uppercase tracking-wider text-sm p-2 text-center"><span className="sr-only 2xs:not-sr-only">Cristiano</span> Ronaldo <span className="sr-only">Favourite Opponents</span></h2>
            <ol className="p-1 flex flex-col md:pl-3 lg:pl-6">
              {ropponents.map((d, index) => (
                <li key={d.opponent} className="border-t border-gray-700 md:flex">
                  <div className="flex items-center border-b border-gray-900 text-sm lg:text-base md:flex-1 md:truncate">
                    <span className="bg-noir-lighten5 p-2 inline-block text-highlight text-sm">{index+1}.</span>
                    <span className="p-1 pl-2 truncate">{d.opponent}</span>
                  </div>
                  <div className={`flex md:flex-1`}>
                    <div className="flex flex-col text-center border-r border-gray-900 flex-auto text-ronaldo">
                      <span className="p-1 pb-0 font-semibold">
                        {this.state.statView === "Goals" ? d.goals : this.state.statView === "Assists" ? d.assists : this.state.statView === "GA" ? d.ga : ''}
                      </span>
                      <span className="text-xs block pb-2 leading-tight">
                        {this.state.statView === "Goals" ? 'Goals' : this.state.statView === "Assists" ? 'Assists' : this.state.statView === "GA" ? 'G+A' : ''}
                      </span>
                    </div>
                    <div className="flex flex-col text-center border-r border-gray-900 flex-auto">
                      <span className="p-1 pb-0 text-gray-500">{d.apps}</span>
                      <span className="text-xs text-gray-600 block pb-2 leading-tight">Apps</span>
                    </div>
                    <div className="flex flex-col text-center flex-auto">
                      <span className="p-1 pb-0 text-gray-500">
                        {this.state.statView === "Goals" ? Number(d.goals / d.apps).toFixed(2) : this.state.statView === "Assists" ? Number(d.assists / d.apps).toFixed(2) : this.state.statView === "GA" ? Number(d.ga / d.apps).toFixed(2) : ''}
                      </span>
                      <span className="text-xs text-gray-600 block pb-2 leading-tight">Per Game</span>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>

        </div>

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiMatchHistory {
      edges {
        node {
          team
          opponent
          goals
          assists
          competition
        }
      }
    }
    allSheetRonaldoMatchHistory {
      edges {
        node {
          team
          opponent
          goals
          assists
          competition
        }
      }
    }
  }
`

export default FavouriteOpponents
